#onetrust-banner-sdk {
  #onetrust-close-btn-container {
    .ot-close-icon {
      height: 25px;
      width: 20px;
    }
  }
}

.elc-search-active-screen-wrapper {
  .elc-search-results-wrapper {
    .elc-main-content-section {
      .elc-product-installments-price-label {
        margin-top: 0;
      }
    }
  }
}

.field-content {
  .sd-product-grid {
    .elc-accordion {
      a.elc-product-cta {
        padding: 5px;
      }
    }
  }
}
